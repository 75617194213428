const metaData = {
  SITE_NAME: `QUTENZA (Capsaicin)`,
  SITE_DEFAULT_DESCRIPTION: `Learn about QUTENZA for diabetic nerve pain treatment and post-shingles nerve pain treatment. Find a QUTENZA Specialist near you. Explore patient savings`,
  SITE_DEFAULT_KEYWORDS: [
    "post-shingles nerve pain",
    "pain after shingles",
    "Postherpetic Neuralgia treatment",
    "qutenza patch",
    "capsaicin patch",
  ],
  PATIENT_JOB_CODE: `QZA-08-22-0024 v8.0 January 2024`,
  CURRENT_YEAR: new Date().getFullYear(),
};

/**
 * HEADER_TYPE : fixed or regular
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */
const ISI_HEIGHT = 60;
const siteOptions = {
  INDICATIONS: ["hcp", "patient"],
  HEADER_TYPE: "fixed",
  STICKY_HEIGHT: ISI_HEIGHT,
  ISI_OPTIONS: {
    TYPE: "sticky",
    STICKY_STYLES: {
      backgroundColor: "#ffffff",
      height: ISI_HEIGHT,
      width: "100%",
      boxShadow: "-1px -5px 15px 0px rgba(0,0,0,0.10)",
      paddingTop: 5,
    },
  },
};
/* const hcpModalInfo = {
  TITLE: `<h2>Intended for U.S. Residents Only<h2>`,
  BODY: `<h3>Welcome to BRANDX.com</h3><p>Are you a patient or a healthcare professional?</p>`,
  BUTTON_ONE_CAPTION: `Patient`,
  BUTTON_TWO_CAPTION: `Healthcare professional`,
  CLASS_NAME: `brandx-modal brandx-hcp-modal`,
}; */
const externalLinkInfo = {
  TITLE: ``,
  BODY: `<p>You are now leaving the QUTENZA website.</p>`,
  LINK_CLASS_NAME: `external-link`,
  OVERLAY_CLASS_NAME: `qutenza-modal qutenza-external-modal`,
};

const appConfigs = {
  metaData: metaData,
  siteOptions: siteOptions,
  // hcpModalInfo: hcpModalInfo,
  externalLinkInfo: externalLinkInfo,
};

export default appConfigs;
