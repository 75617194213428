exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dpn-about-qutenza-js": () => import("./../../../src/pages/dpn/about-qutenza.js" /* webpackChunkName: "component---src-pages-dpn-about-qutenza-js" */),
  "component---src-pages-dpn-diabetic-nerve-pain-of-the-feet-js": () => import("./../../../src/pages/dpn/diabetic-nerve-pain-of-the-feet.js" /* webpackChunkName: "component---src-pages-dpn-diabetic-nerve-pain-of-the-feet-js" */),
  "component---src-pages-dpn-index-js": () => import("./../../../src/pages/dpn/index.js" /* webpackChunkName: "component---src-pages-dpn-index-js" */),
  "component---src-pages-dpn-ongoing-relief-js": () => import("./../../../src/pages/dpn/ongoing-relief.js" /* webpackChunkName: "component---src-pages-dpn-ongoing-relief-js" */),
  "component---src-pages-dpn-resources-js": () => import("./../../../src/pages/dpn/resources.js" /* webpackChunkName: "component---src-pages-dpn-resources-js" */),
  "component---src-pages-dpn-starting-qutenza-js": () => import("./../../../src/pages/dpn/starting-qutenza.js" /* webpackChunkName: "component---src-pages-dpn-starting-qutenza-js" */),
  "component---src-pages-find-a-qutenza-specialist-js": () => import("./../../../src/pages/find-a-qutenza-specialist.js" /* webpackChunkName: "component---src-pages-find-a-qutenza-specialist-js" */),
  "component---src-pages-important-safety-information-js": () => import("./../../../src/pages/important-safety-information.js" /* webpackChunkName: "component---src-pages-important-safety-information-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-phn-about-qutenza-js": () => import("./../../../src/pages/phn/about-qutenza.js" /* webpackChunkName: "component---src-pages-phn-about-qutenza-js" */),
  "component---src-pages-phn-index-js": () => import("./../../../src/pages/phn/index.js" /* webpackChunkName: "component---src-pages-phn-index-js" */),
  "component---src-pages-phn-post-shingles-nerve-pain-js": () => import("./../../../src/pages/phn/post-shingles-nerve-pain.js" /* webpackChunkName: "component---src-pages-phn-post-shingles-nerve-pain-js" */),
  "component---src-pages-phn-starting-qutenza-js": () => import("./../../../src/pages/phn/starting-qutenza.js" /* webpackChunkName: "component---src-pages-phn-starting-qutenza-js" */),
  "component---src-pages-save-on-qutenza-treatment-js": () => import("./../../../src/pages/save-on-qutenza-treatment.js" /* webpackChunkName: "component---src-pages-save-on-qutenza-treatment-js" */),
  "component---src-pages-stay-connected-js": () => import("./../../../src/pages/stay-connected.js" /* webpackChunkName: "component---src-pages-stay-connected-js" */)
}

