import React, { useState, useEffect } from 'react';
import appConfigs from '../../../app.settings';
import { CookiesProvider } from 'react-cookie';

export const appContext = React.createContext();

const AppProvider = props => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [currentIndication, setCurrentIndication] = useState('');
  const [magicshot, setMagicshot] = useState(false);
  const [topOffset, setTopOffset] = useState(0);
  const [headerPageList, setHPL] = useState([]);
  const [navActive, setNavActive] = useState(null);
  const [isHamburgerActive, setIsHamburgerActive] = useState(false);
  const [scrollStarted, setScrollStarted] = useState(false);
  const [isAtIndication, setIsAtIndication] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const updateHeaderHeight = _height => {
    setHeaderHeight(_height);
  };

  const updateCurrentIndication = _indication => {
    setCurrentIndication(_indication);
  };

  const updateMagicshot = _flag => {
    setMagicshot(_flag);
  };

  const updateHeaderPageList = (list) => {
    setHPL(list);
  }

  const updateNavActive = (nav) => {
    setNavActive(nav)
  }

  useEffect(() => {
    appConfigs.siteOptions.HEADER_TYPE === 'fixed'
      ? setTopOffset(headerHeight)
      : setTopOffset(0);
  }, [headerHeight]);
  return (
    <appContext.Provider
      value={{
        appConfigs,
        headerPageList,
        updateHeaderPageList,
        currentIndication,
        headerHeight,
        magicshot,
        topOffset,
        updateHeaderHeight,
        updateCurrentIndication,
        updateMagicshot,
        navActive,
        updateNavActive,
        isHamburgerActive,
        setIsHamburgerActive,
        isAtIndication,
        setIsAtIndication,
        scrollStarted,
        setScrollStarted,
        isVideoModalOpen,
        setIsVideoModalOpen,
      }}
    >
      {props.children}
    </appContext.Provider>
  );
};
// Assign the arrow function to a variable before exporting it
const AppProviderWithCookies = ({ element }) => (
  <AppProvider>
    <CookiesProvider>{element}</CookiesProvider>
  </AppProvider>
);

export default AppProviderWithCookies;
